var aos = require('aos');

function f_inputEffect() {
    $('.form-control-bis').each(function (i) {
        isEmpty = $(this).val();
        if (!isEmpty == '') {
            $(this).addClass('active');
        }

        $(this).on('blur', function () {
            if ($(this).val() === '') {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');

            }
        });
    });
}

f_inputEffect();

function f_resa_options() {
    $('#options input[type=checkbox]').on('change', function () {
        checkbox($(this));
        request();
    });

    $('#options .quantite select').on('change', function () {
        $(this).parent().parent().parent().parent().find('input').prop('value', $(this).val());
        request();
    });

    // Initialisation
    request();
    $('#options input[type=checkbox]').each(function () {
        checkbox($(this));
    });

    function checkbox(self) {
        var select = self.parent().parent().find('select');
        var quantite = self.parent().parent().find('.quantite select');
        if (self.is(":checked")) {
            select.prop('disabled', false);
            select.parent().parent().parent().removeClass('disabled');
        } else {
            select.prop('disabled', true);
            select.parent().parent().parent().addClass('disabled');
        }
        self.prop('value', quantite.val());
    }

    function request() {
        $.ajax({
            method: "POST",
            url: $("#options").data('ajax-url'),
            data: $("#devis").serialize(),
            success: function(data) {
                $("#total").text(data.total);
                $("#options-montant").text(data.options);
                var options_element = $("#options-montant").parent().parent();
                if (data.options == 0) {
                    options_element.hide();
                } else {
                    options_element.show();
                }
                $("#assurances-montant").text(data.assurances);
                var assurances_element = $("#assurances-montant").parent().parent();
                if (data.assurances == 0) {
                    assurances_element.hide();
                } else {
                    assurances_element.show();
                }
                $("#reductions-montant").text(data.reduction_montant);
                var reduction_element = $("#reductions-montant").parent().parent();
                if (data.reduction_montant == 0) {
                    reduction_element.hide();
                } else {
                    reduction_element.show();
                }
                var promotions = '';
                if (data.promotions) {
                    promotions = '* ';
                    data.promotions.forEach(function(promotion){
                        promotions += promotion.nom + ' : ' + promotion.extrait + ' ; ';
                    });
                }
                $("#reductions-description").text(promotions);
            }
        });
    }

}

if ($('#options').length > 0) {
    f_resa_options();
}

function f_Carrosserie() {
    $("#carrosserie").on('change', function () {
        if ($(this).val() !== '') {
            $('.resa-car').hide();
            $('.resa-car[data-carrosserie=' + $(this).val() + ']').show();
        } else {
            $('.resa-car').show();
        }

        $('#search-count').text($('.resa-step-1:visible:not(.unavailable)').length);
        aos.refresh();
    });
    $("#carrosserie").trigger('change');
}

f_Carrosserie();

function f_CarSite() {
    $("#car_site button").on('click', function () {
        $('.resa-car').hide();
        $('.resa-car[data-site=' + $(this).data('value') + ']').show();
        $('#car_site button').removeClass('red').addClass('gray');
        $(this).addClass('red').removeClass('gray');

        aos.refresh();
    });
    $("#car_site").trigger('click');
}

f_CarSite();


function f_SaveReservation() {
    $("#email_validation").on('blur', function () {
        if ($(this).data('pre-commande') && validateEmail($(this).val())) {
            $.ajax({
                method: "POST",
                url: $(this).data('ajax-url'),
                data: $("#form-validation").serialize(),
            })
        }
    });
}
f_SaveReservation();


function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}