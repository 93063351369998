function f_more_info_toggle(){
    $('.info-toggle-wp').hide();
    $('.more-info-trigger').on('click', function(){
        var target = $(this).attr('data-trigger');
        $('#'+target).slideToggle();
        return false;
    });
    $('.info-toggle .close').on('click',function(){
        $(this).parents().eq(2).slideUp();
        return false;
    });
}

function reservation_toggle_bis() {
    var form = $('#resa-home-wp.except-resa');
    var btn = $('#trigger-resa-nav');
    form.hide();
    btn.on('click', function(){
        form.toggle();
        btn.toggleClass('open');
        return false;
    });
}

function reservation_end() {
    $('#place-start').on('change', function () {
        $('#place-end').val($('#place-start').val()).change();
    });
}

function faq_toggle(){
    $('.item-faq .content').hide();
    $('.item-faq .button').on('click',function(){
        var isOpen = $(this).parents().eq(1).hasClass('open');
        if(isOpen){
            $('.item-faq .content').hide();
            $('.item-faq').removeClass('open');
        }else{
            $('.item-faq .content').hide();
            $('.item-faq').removeClass('open');
            $(this).parent().next().toggle();
            $(this).parents().eq(1).toggleClass('open');
        }
        return false;
    });
}

function resa_home() {
    var parent = $('#resa-home-wp');
    parent.find('.selectpicker-wp .title').on('click',function() {
        $(this).next().click();
        $(this).next().find('.selectpicker').selectpicker('toggle');
    });
    parent.find('.bootstrap-select').on('click', function(){
        parent.addClass('opened');
    });

    parent.find('input').on('focusin', function(){
        parent.addClass('opened');
    });
}

function header_lang() {
    var selector = $("#trigger-lang");
    var target = selector.find('.dropdown-list');
    selector.on('click', function (){
        target.toggle();
    });
}
function header_resa(){
    var selector = $("#trigger-resa");
    var target = selector.find('.dropdown-list');
    selector.on('click', function (){
        target.toggle();
        selector.toggleClass('open');
    });
}

// Navigation mobile
function nav_mobile() {
    $('#nav-mobile-trigger').on('click', function() {
        $('body').toggleClass('menu-open');
        $('#nav-main').toggle();
    });
}

// Navigation size
function nav_size() {
    var position = $(window).scrollTop();
    if (position > 220) {
        $('body').addClass('header-small');
    } else {
        $('body').removeClass('header-small');
    }
    $(window).scroll(function() {
        if ($(this).scrollTop() > 220) {
            $('body').addClass('header-small');
        } else {
            $('body').removeClass('header-small');
        }
    });
}

// Navigation sub menu
function nav_sub_menu() {
    var viewport = $(window).width();
    if (viewport < 576) {
        $('#nav-main li[data-navsub] > a').on('click', function(event) {
            console.log('sub menu')
            $(this).next().toggle();
            return false;
        });
    } else {
        $('#nav-main li[data-navsub] > a').on('click', function(event) {
            var navSub = $(this).parent();
            var isFocus = navSub.hasClass('focus');
            $('#nav-main li[data-navsub]').removeClass('focus');
            if (!isFocus) {
                navSub.addClass('focus');
            }
            event.stopPropagation();
            return false;
        });
        $('html').click(function() {
            $('#nav-main li[data-navsub]').removeClass('focus');
        });
    }
}

// Scroll top button
function scroll_top() {
    $('#btn-back-top').fadeOut(0);
    $(window).scroll(function() {
        if ($(this).scrollTop() > 220) {
            $('#btn-back-top').fadeIn(300);
        } else {
            $('#btn-back-top').fadeOut(300);
        }
    });
    $('#btn-back-top').on('click', function() {
        $('html, body').animate({
            scrollTop: 0
        }, '500');
        return false;
    });
}

function f_resaHome(){
    $('#resa-home-toggle').hide();
    $('#place-checkbox').on('change', function(){
        $('#resa-home-toggle').toggle();
        return false;
    });
}

function nav_form_resa_fixed() {
    var headerH = $('#resa-header').height();
    var formH = $('#resa-fixed').innerHeight();
    var position = $(window).scrollTop();
    if (position > headerH) {
        $('body').addClass('form-resa-fixed');
        $('body').css('paddingTop', formH);
    } else {
        $('body').removeClass('form-resa-fixed');
        $('body').css('paddingTop', 0);
    }
    $(window).scroll(function() {
        if ($(this).scrollTop() > headerH) {
            $('body').addClass('form-resa-fixed');
            $('body').css('paddingTop', formH);
        } else {
            $('body').removeClass('form-resa-fixed');
            $('body').css('paddingTop', 0);
        }
    });

}


function nav_form_resa_toggle(){
    $('#resa-header-summary .link-edition.date').on('click',function(){
        $('#resa-search').toggleClass('hide');
        return false;
    });
}

function nav_form_resa_blink() {
    $('.resa-anchor').on('click', function () {
        $('#datepicker-start').trigger('focus');
    });
}

// Initialisation
f_resaHome();
nav_mobile();
nav_sub_menu();
nav_size();
scroll_top();
nav_form_resa_fixed();
nav_form_resa_toggle();
header_lang();
header_resa();
resa_home();
faq_toggle();
reservation_toggle_bis();
nav_form_resa_blink();
f_more_info_toggle();
reservation_end();

$(window).on('resize', function() {
    nav_form_resa_fixed();
});
