// JS dependency
var datepicker = require('jquery-ui/ui/widgets/datepicker');
var local = $("html").attr('lang').slice(0,2);
if(local === 'fr'){
    $.datepicker.regional['fr'] = require('jquery-ui/ui/i18n/datepicker-fr');
}

// CSS dependencies
import '../../node_modules/jquery-ui/themes/base/theme.css';
import '../../node_modules/jquery-ui/themes/base/datepicker.css';

var numberOfMonths = window.matchMedia("(min-width: 900px)").matches ? 2 : 1;

// Input Start
$("#datepicker-start").datepicker({
    showOn: "both",
    buttonText: "<span class='sprite arrow-dropdown-black'></span>",
    //buttonImage: 'theme/images/icons/gmap-marker.png',
    buttonImageOnly: false,
    defaultDate: "+1w",
    numberOfMonths: numberOfMonths,
    minDate: 0,
    maxDate: '1Y',
    dateFormat: 'dd/mm/yy',
    beforeShowDay: highlightDates,
    onClose: function( selectedDate ) {
        $("#datepicker-end").datepicker( "option", "minDate", selectedDate );
        $("#datepicker-end").focus();
    }
});

// Input End
$("#datepicker-end").datepicker({
    beforeShowDay: highlightDates,
    showOn: "both",
    buttonText: "<span class='sprite arrow-dropdown-black'></span>",
    //buttonImage: 'theme/images/icons/gmap-marker.png',
    buttonImageOnly: false,
    defaultDate: "+1w",
    maxDate: '1Y',
    numberOfMonths: numberOfMonths,
    dateFormat: 'dd/mm/yy'
});

// fonction changement date debut
$('#datepicker-start').change(function() {
    // on récupère la date de début
    var date_fin = $('#datepicker-start').datepicker('getDate');
    //on ajoute 7 jours à la date de début pour obtenir la dete de fin
    date_fin.setDate(date_fin.getDate() + 7);
    //maj date fin dans datapicker
    $('#datepicker-end').datepicker('setDate', date_fin);
});

// Fonction pour ajouter une classe aux dates entre deux dates
function highlightDates(date) {
    var startDate = $('#datepicker-start').datepicker('getDate');
    var endDate = $('#datepicker-end').datepicker('getDate');

    // Vérifier si la date est entre la date de début et la date de fin
    if (startDate && endDate) {
        var currentDate = formatDate($.datepicker.formatDate('dd/mm/yy', date));
        var formattedStartDate = formatDate($('#datepicker-start').val());
        var formattedEndDate = formatDate($('#datepicker-end').val());

        if (currentDate === formattedStartDate || currentDate === formattedEndDate) {
            return [true, 'highlighted-start-end'];
        } else if (date > startDate && date < endDate) {
            return [true, 'highlighted-between', 'highlighted-between'];
        }
    }

    return [true, ''];
}

// Fonction pour formater les dates (dd/mm/yy to mm/dd/yy)
function formatDate(dateString) {
    var parts = dateString.split('/');
    return parts[1] + '/' + parts[0] + '/' + parts[2];
}

// date de naissance & date de permis
$(".input-datepicker").each(function(index) {
    $(this).datepicker({
        defaultDate: "+1w",
        numberOfMonths: 1,
        minDate: '-90Y',
        maxDate: $(this).data('minimum'),
        changeMonth: true,
        changeYear: true,
        yearRange: "-90:+0",
        showOn: "both",
        buttonText: "<span class='sprite arrow-dropdown-black'></span>",
        dateFormat: 'dd/mm/yy',
        //buttonImage: "/theme/images/icons/datepicker-icon.png"
    });
});